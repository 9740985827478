import { ArrayParse, LabelValueDTO } from '@uefa-shared/contracts'
import { IsOptional, IsUUID } from 'class-validator'
import { ExportStatus, MediaType, ReportType, SVROrganization, WorkingVisitStatus } from '../shared'

export class MonitorCounter {
  nTotalQuestions: number
  nTotalActionPoints: number
  nActiveActionPoints: number
  nActiveQuestions: number
}

export class MonitorTimelineEntry {
  id?: string
  date: Date
  status: WorkingVisitStatus
  userName: string
  reportUrl?: string
  reportStatus?: ExportStatus
  zipUrl?: string
  zipStatus?: ExportStatus
  teamMovedToEventShortName?: string
  teamMovedFromEventShortName?: string

  constructor(media?: MonitorMediaFile) {
    if (media) {
      this.date = media.uploadDate
      this.userName = media.uploadedBy
      this.status = null
    }
  }
}

export class MonitorWorkingVisitSearchDTO {
  @IsOptional()
  @IsUUID()
  eventId?: string
}

export class MonitorWorkingVisitSummarySearchDTO {
  @IsOptional()
  @IsUUID()
  workingVisitId?: string

  @IsOptional()
  @IsUUID()
  eventId?: string
}

export class ExportOptionsSearchDTO {
  @IsOptional()
  @ArrayParse()
  workingVisitIds?: string[]

  @IsOptional()
  @ArrayParse()
  eventIds?: string[]
}
export class MonitorWorkingVisitSummary {
  counter: MonitorCounter
  timelineInfo: MonitorTimelineEntry[]
}

export class ExportOptionsDTO {
  audience?: LabelValueDTO[]
  hasActionPoints?: boolean
  hasEditors?: boolean
  showCompleted?: boolean
  hasTodoQuestions?: boolean
  hasPrefill?: boolean
  hasPrefillQuestions?: boolean
}

export class MonitorMediaFile {
  name: string
  fileName?: string
  id?: string
  mediaType?: MediaType
  mimeType?: string
  url?: string
  thumbnailUrl?: string
  uploadDate?: Date
  uploadedBy?: string
  organization?: SVROrganization
  reportType?: ReportType
  ownerName?: string
  reportId?: string
  exportMedia?: boolean
  exportStatus?: ExportStatus
  uploadSuccess?: boolean
  order?: number
}

export class WorkingVisitSubmitDTO {
  @IsOptional()
  @IsUUID()
  eventId?: string

  @IsOptional()
  @ArrayParse()
  projectIds?: string[]
}
