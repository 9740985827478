import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PaginatedResults } from '@uefa-shared/contracts'
import { BaseService } from '@uefa-shared/frontend'
import {
  WorkingVisitQuestionAproveDTO,
  WorkingVisitQuestionDisableDTO,
  WorkingVisitQuestionDTO,
  WorkingVisitQuestionListDTO,
  WorkingVisitQuestionRejectDTO,
  WorkingVisitQuestionSearchBulkUpdateDTO,
  WorkingVisitQuestionSearchDTO,
  WorkingVisitQuestionUpdateDTO,
} from '@uefa-svr/contracts'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../environments/environment'
import { ExportResponse } from './action-point.service'

@Injectable({ providedIn: 'root' })
export class WorkingVisitQuestionService extends BaseService {
  constructor(http: HttpClient) {
    super(environment, 'working-visit-question', http)
  }

  public getQuestion(id: string): Observable<WorkingVisitQuestionDTO> {
    return this.http.get<WorkingVisitQuestionDTO>(`${this.basePath}/${id}`)
  }

  public getExportUrl(search: WorkingVisitQuestionSearchDTO): string {
    const queryParams = this.getQueryParams<WorkingVisitQuestionSearchDTO>(search)
    return `${this.basePath}/export?${queryParams.join('&')}`
  }

  public getSiblingQuestion(id: string, workingVisitId: string): Observable<{ id: string }> {
    return this.http.get<{ id: string }>(`${this.basePath}/${id}/sibling/${workingVisitId}`)
  }

  public updateQuestion(id: string, data: WorkingVisitQuestionUpdateDTO): Observable<WorkingVisitQuestionDTO> {
    return this.http.put<WorkingVisitQuestionDTO>(`${this.basePath}/${id}`, data)
  }

  public rejectQuestion(
    id: string,
    comment: string,
    eventId: string,
    updateModel: WorkingVisitQuestionUpdateDTO
  ): Observable<WorkingVisitQuestionDTO> {
    return this.http.put<WorkingVisitQuestionDTO>(
      `${this.basePath}/${id}/reject`,
      new WorkingVisitQuestionRejectDTO(comment, eventId, updateModel)
    )
  }

  public approve(id: string, eventId: string, model: WorkingVisitQuestionUpdateDTO): Observable<WorkingVisitQuestionDTO> {
    return this.http.put<WorkingVisitQuestionDTO>(`${this.basePath}/${id}/approve`, new WorkingVisitQuestionAproveDTO(model, eventId))
  }

  public getPaginated(options: WorkingVisitQuestionSearchDTO): Observable<PaginatedResults<WorkingVisitQuestionListDTO>> {
    return this.getPaginatedResult<WorkingVisitQuestionListDTO>(this.basePath, options)
  }

  public disableQuestions(data: WorkingVisitQuestionDisableDTO) {
    return this.http.put<{ ids: string[] }>(`${this.basePath}/disable`, data)
  }

  public approveQuestions(
    data: WorkingVisitQuestionSearchDTO
  ): Observable<{ ids: string[]; allQuestionsApproved: boolean; invalidQuestionsToApprove: boolean }> {
    return this.http.put<{ ids: string[]; allQuestionsApproved: boolean; invalidQuestionsToApprove: boolean }>(
      `${this.basePath}/bulk-approve`,
      data
    )
  }

  public statusChangeQuestions(
    data: WorkingVisitQuestionSearchBulkUpdateDTO
  ): Observable<{ ids: string[]; allQuestionsStatusChanged: boolean }> {
    return this.http.put<{ ids: string[]; allQuestionsStatusChanged: boolean }>(`${this.basePath}/bulk-status-change`, data)
  }

  public generateFieldExport(id: string, fieldId: string): Observable<ExportResponse> {
    const request = this.http.get(`${this.basePath}/${id}/field/${fieldId}/export`, {
      responseType: 'arraybuffer',
      observe: 'response',
    })

    return request.pipe(
      map((res) => {
        const response = new ExportResponse()
        response.data = { data: res.body }
        const contentDisposition = res.headers.get('Content-Disposition')
        response.name = contentDisposition.match(/filename="(.*)"/)[1]
        return response
      })
    )
  }
}
