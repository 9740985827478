import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MediaField } from '@uefa-shared/frontend'
import { MediaListOptions } from '../..'
import { environment } from '../../../../environments/environment'
import { SignatureDialogComponent, SignatureDialogOptions, SignatureDialogResult } from './signature-dialog/signature-dialog.component'

@Component({
  selector: 'svr-signature-field',
  templateUrl: './signature-field.component.html',
})
export class SignatureFieldComponent {
  @Input() title: string
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('disabled') set changeDisabled(disabled: boolean) {
    this.disabled = disabled

    this.mediaListOptions.addButton = !this.disabled
    this.mediaListOptions.deleteButton = !this.disabled
  }
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('signatures') set changeSignatures(signatures: MediaField[]) {
    this.signatures = signatures.map((s) => ({ ...s, url: this.getSignatureUrl(s) }))
  }

  @Output() focusChange = new EventEmitter<void>()
  @Output() blurChange = new EventEmitter<void>()
  @Output() signaturesChange = new EventEmitter<MediaField[]>()

  public selectedSignature: MediaField = null
  public signatures: MediaField[] = []

  public disabled = false

  public mediaListOptions: MediaListOptions = {
    withMediaGallery: false,
    editButton: false,
    customInputElement: true,
  }

  constructor(private readonly dialog: MatDialog) {}

  public onSignatureClick(signature: MediaField) {
    this.selectedSignature = this.signatures.find((s) => s.id === signature.id)
    this.openSignatureDialog()
  }

  public onSignaturesChange(signatures: MediaField[]) {
    this.signatures = signatures
    this.signaturesChange.emit(this.signatures)
  }

  public getSignatureUrl(signature: MediaField) {
    if (signature.isNewImage) return signature.url
    return signature.url?.includes(environment.apibaseUrl) ? signature.url : `${environment.apibaseUrl}/${signature.url}`
  }

  public onFocus() {
    this.focusChange.emit()
  }

  public onBlur() {
    this.blurChange.emit()
  }

  public openSignatureDialog() {
    const signatureHeight = 450
    const signatureWidth = 450

    this.onFocus()

    this.dialog
      .open<SignatureDialogComponent, SignatureDialogOptions, SignatureDialogResult>(SignatureDialogComponent, {
        data: {
          height: signatureHeight,
          width: signatureWidth,
          title: this.title,
          selected: this.selectedSignature,
          disabled: this.disabled,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        this.onBlur()

        if (!res) {
          this.selectedSignature = null
          return
        }

        const isNewSignature = !this.selectedSignature
        if (isNewSignature) {
          this.addNewSignature(res)
        } else {
          this.updateSignature(res)
        }
      })
  }

  private updateSignature(res: SignatureDialogResult) {
    this.selectedSignature.name = res.name
    this.selectedSignature.organization = res.organization
    this.onSignaturesChange(this.signatures)
    this.selectedSignature = null
  }

  private addNewSignature(res: SignatureDialogResult) {
    const newSignature = this.mapSignatureDialogResultToMediaField(res)
    this.signatures.push(newSignature)
    this.onSignaturesChange(this.signatures)
  }

  private mapSignatureDialogResultToMediaField(result: SignatureDialogResult): MediaField {
    const dto = new MediaField()

    dto.id = `fake_${+new Date()}`
    dto.mimeType = 'image/png'
    dto.name = result.name
    dto.organization = result.organization
    dto.isNewImage = true

    // decode from base 64
    const blobBin = atob(result.image.src.split(',')[1])
    const byteArray: number[] = []
    for (let i = 0; i < blobBin.length; i++) {
      // write the bytes to an array
      byteArray.push(blobBin.charCodeAt(i))
    }

    // create the File object
    dto.file = new File([new Uint8Array(byteArray)], result.name, { type: 'image/png' })
    dto.url = result.image.src

    return dto
  }
}
