import { Exclude } from 'class-transformer'
import { ArrayNotEmpty, IsArray, IsEnum, IsOptional } from 'class-validator'
import { ApplicationName } from '../application'
import { ArrayParse, StringTrim } from '../decorators'
import { PaginatedSearchDTO } from '../shared'
import { VistaDTO } from '../shared/vista.dto'
import { SuiteUserSetting } from './suite-user-setting.dto'

export enum UserGroupRole {
  ADMINISTRATOR = 'admin',
  MANAGER = 'manager',
  BROADCASTER = 'broadcaster',
  BROADCASTER_MANAGER = 'broadcastermanager',
  EVENT_REPORTING = 'eventreporting',
  LIVE_LOGGING = 'livelogging',
  EDITOR = 'editor',
  READER = 'reader',
  HOHB_ADMIN = 'hohb-admin',
  HOHB_MANAGER = 'hohb-manager',
  HOHB_EDITOR = 'hohb-editor',
  HOHB_READER = 'hohb-reader',
  ACTION_POINT_EDITOR = 'action-point-editor',
  HOHB_ACTION_POINT_EDITOR = 'hohb-action-point-editor',
}

export class UserGroupDTO {
  displayName?: string
  projectId?: string
  projectIntegrationId?: string
  projectName?: string
  eventId?: string
  eventIntegrationId?: string
  eventName?: string
  venueId?: string
  venueIntegrationId?: string
  venueName?: string
  role?: UserGroupRole
}

export interface FilterOptions {
  eventId?: string | string[]
  eventIntegrationId?: string | string[]
  projectId?: string | string[]
  projectIntegrationId?: string | string[]
  venueId?: string | string[]
  venueIntegrationId?: string | string[]
}

export class UserPermissionDTO {
  private static readonly DELIMETER = ':'

  public entityToKey: { [entity: string]: string } = {}
  public keyToEntity: { [key: string]: string } = {}
  public groups: string[] = []

  public static create(payload: {
    entityToKey: { [entity: string]: string }
    keyToEntity: { [key: string]: string }
    groups: string[]
  }): UserPermissionDTO {
    const permissions = new UserPermissionDTO()
    permissions.entityToKey = payload.entityToKey
    permissions.keyToEntity = payload.keyToEntity
    permissions.groups = payload.groups

    return permissions
  }

  public addGroup(group: UserGroupDTO) {
    const roleInitial = this.roleToInitial(group.role)
    const eId = this.transformEntityId(group.eventId, group.eventIntegrationId)
    const vId = this.transformEntityId(group.venueId, group.venueIntegrationId)
    const pId = this.transformEntityId(group.projectId, group.projectIntegrationId)

    const delimiter = UserPermissionDTO.DELIMETER
    this.groups.push(`${roleInitial}${delimiter}${eId}${delimiter}${vId}${delimiter}${pId}`)
  }

  public getUserGroupsByRoles(roles: UserGroupRole[], options?: FilterOptions, strictSearch = false): UserGroupDTO[] {
    const userGroupRoles = Object.values(UserGroupRole)

    if (!strictSearch) {
      // Set roles with higher level
      for (const userGroupRole of userGroupRoles) {
        if (roles.some(r => r === userGroupRole)) {
          break
        }
        roles.push(userGroupRole)
      }
    }

    const userGroups = this.groups.map(g => this.mapToUserGroup(g))
    return userGroups
      .filter(g => roles.includes(g.role))
      .filter(g => this.groupFilter(g.eventId, options?.eventId))
      .filter(g => this.groupFilter(g.eventIntegrationId, options?.eventIntegrationId))
      .filter(g => this.groupFilter(g.projectId, options?.projectId))
      .filter(g => this.groupFilter(g.projectIntegrationId, options?.projectIntegrationId))
      .filter(g => this.groupFilter(g.venueId, options?.venueId))
      .filter(g => this.groupFilter(g.venueIntegrationId, options?.venueIntegrationId))
  }

  public getGlobalGroups(): UserGroupDTO[] {
    return this.groups
      .filter(g => {
        const [_, eventId, venueId, projectId] = g.split(UserPermissionDTO.DELIMETER)
        return !projectId && !eventId && !venueId
      })
      .map(g => this.mapToUserGroup(g))
  }

  public getGlobalRoles(): UserGroupRole[] {
    return this.getGlobalGroups().map(g => g.role)
  }

  public hasGroups(): boolean {
    return this.groups?.length > 0
  }

  private initialToRole(initial: string): UserGroupRole {
    switch (initial) {
      case 'a':
        return UserGroupRole.ADMINISTRATOR
      case 'm':
        return UserGroupRole.MANAGER
      case 'e':
        return UserGroupRole.EDITOR
      case 'b':
        return UserGroupRole.BROADCASTER
      case 'bm':
        return UserGroupRole.BROADCASTER_MANAGER
      case 'er':
        return UserGroupRole.EVENT_REPORTING
      case 'll':
        return UserGroupRole.LIVE_LOGGING
      default:
        return UserGroupRole.READER
    }
  }

  private roleToInitial(role: UserGroupRole): string {
    switch (role) {
      case UserGroupRole.ADMINISTRATOR:
        return 'a'
      case UserGroupRole.MANAGER:
        return 'm'
      case UserGroupRole.EDITOR:
        return 'e'
      case UserGroupRole.BROADCASTER:
        return 'b'
      case UserGroupRole.BROADCASTER_MANAGER:
        return 'bm'
      case UserGroupRole.EVENT_REPORTING:
        return 'er'
      case UserGroupRole.LIVE_LOGGING:
        return 'll'
      default:
        return 'r'
    }
  }

  private mapToUserGroup(group: string): UserGroupDTO {
    const [role, eventId, venueId, projectId] = group.split(UserPermissionDTO.DELIMETER)

    const [originalEventId, originalEventIntegrationId] = this.getEntityId(eventId)
    const [originalVenueId, originalVenueIntegrationId] = this.getEntityId(venueId)
    const [originalProjectId, originalProjectIntegrationId] = this.getEntityId(projectId)

    const userGroup = new UserGroupDTO()
    userGroup.role = this.initialToRole(role)
    userGroup.eventId = originalEventId
    userGroup.eventIntegrationId = originalEventIntegrationId
    userGroup.venueId = originalVenueId
    userGroup.venueIntegrationId = originalVenueIntegrationId
    userGroup.projectId = originalProjectId
    userGroup.projectIntegrationId = originalProjectIntegrationId
    return userGroup
  }

  private getEntityId(key: string): [string, string] {
    if (!key) {
      return [null, null]
    }

    const [originalId, originalIntegrationId] = this.keyToEntity[key].split(UserPermissionDTO.DELIMETER)
    return [originalId, originalIntegrationId]
  }

  private groupFilter(roleEntityId: string, optionEntityIds?: string | string[]): boolean {
    if (!optionEntityIds || !optionEntityIds.length) {
      return true
    }

    if (!roleEntityId) {
      return true
    }

    optionEntityIds = Array.isArray(optionEntityIds) ? optionEntityIds : [optionEntityIds]
    return optionEntityIds.includes(roleEntityId)
  }

  private transformEntityId = (id: string, integrationId: string): string => {
    if (!id) {
      return ''
    }

    const existingKey = this.entityToKey[id]
    if (existingKey) {
      return existingKey
    }

    const entityIndex = Object.keys(this.entityToKey).length + 1
    const key = entityIndex.toString(16)

    this.entityToKey[id] = key
    this.keyToEntity[key] = `${id}${UserPermissionDTO.DELIMETER}${integrationId}`

    return key
  }
}

export class UserRole {
  role: UserGroupRole
  isGlobal?: boolean
}

export class UserDTO extends VistaDTO {
  @Exclude()
  id?: string
  upn?: string
  fullName?: string
  isDeleted?: boolean
  timezone?: string
  existsInAM?: boolean
  existsInSVR?: boolean
  isBroadcaster?: boolean
  isGlobalAdmin?: boolean
  isBroadcasterManager?: boolean
  active?: boolean
  externalId?: string
  @Exclude()
  groups?: UserPermissionDTO
  settings?: UserSetting
  suiteSettings?: SuiteUserSetting
  roles?: UserRole[]
  phone?: string
  applications?: ApplicationName[]

  constructor(init?: Partial<UserDTO>) {
    super()
    Object.assign(this, init)
  }
}

export class UserUpdateDTO {
  @IsOptional()
  @StringTrim()
  phone?: string
}

export type BaseMap = 'google-roadmap' | 'google-hybrid' | 'uefa-light' | 'uefa-dark'

export class SiteSetting {
  eventTabOpened: boolean

  constructor() {
    this.eventTabOpened = true
  }
}

export class OverlaySetting {
  baseMap: BaseMap
  panorama360Enabled: boolean
  dotPlanEnabled: boolean
  flowsEnabled: boolean
  zoningPerimeterEnabled: boolean
  keysEnabled: boolean
  blueprintsEnabled: boolean
  labelsEnabled: boolean
  overviewButtonEnabled?: boolean

  constructor() {
    this.baseMap = 'uefa-dark'
    this.panorama360Enabled = true
    this.dotPlanEnabled = true
    this.flowsEnabled = true
    this.zoningPerimeterEnabled = true
    this.keysEnabled = true
    this.blueprintsEnabled = true
    this.overviewButtonEnabled = false
    this.labelsEnabled = true
  }
}

export class UserSetting {
  lastUrl: string
  sidePanelOpened: boolean
  bottomPanelOpened: boolean
  site: SiteSetting
  overlay: OverlaySetting
  termsAndConditions: boolean
  termsAndConditionsTimestamp: number

  constructor() {
    this.lastUrl = null
    this.sidePanelOpened = true
    this.bottomPanelOpened = true
    this.overlay = new OverlaySetting()
    this.site = new SiteSetting()
    this.termsAndConditions = false
    this.termsAndConditionsTimestamp = null
  }
}

export class UserTermsAndConditions {
  public termsAndConditions: boolean
  public termsAndConditionsTimestamp?: number

  constructor(userSettings: UserSetting) {
    this.termsAndConditions = userSettings?.termsAndConditions || false
    this.termsAndConditionsTimestamp = userSettings?.termsAndConditionsTimestamp || null
  }
}

export class UserSynchDTO {
  @IsArray()
  @ArrayNotEmpty()
  userIds: string[]
}

export class TokenKeyDTO {
  tokenKey: string
}

export class UserListDTO {
  id?: string
  name?: string
  email?: string
  initials?: string
  active?: boolean
  applications?: string[]
  designations?: string[]
  existsInAM?: boolean
  existsInSVR?: boolean
  phone?: string
}

export enum UserSearchSortField {
  NAME = 'name',
  EMAIL = 'email',
  EXTERNAL_ID = 'externalId',
  ACTIVE = 'active',
  PHONE = 'phone',
}

export class UserListSearchDTO extends PaginatedSearchDTO {
  @IsOptional()
  @IsArray()
  @ArrayParse()
  applications?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  eventIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  venueIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  projectIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  roles?: UserGroupRole[]

  @IsOptional()
  @IsEnum(UserSearchSortField)
  sortBy?: string
}
