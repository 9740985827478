import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MediaField } from '@uefa-shared/frontend'
import { MediaGalleryItem, mimeTypes } from '@uefa-svr/contracts'
import { MediaListComponent, MediaListOptions } from '../media-list'
import {
  SignatureDialogComponent,
  SignatureDialogOptions,
  SignatureDialogResult,
} from '../signature-field/signature-dialog/signature-dialog.component'

const containerOptions: MediaListOptions = {
  addButton: false,
}

@Component({
  selector: 'svr-media-list-container',
  templateUrl: './media-list-container.component.html',
  styleUrls: ['./media-list-container.component.scss'],
})
export class MediaListContainerComponent {
  @Input() title: string
  @Input() medias: MediaField[] = []
  @Input() disabled: boolean
  @Input() signaturePicker?: boolean
  @Input() onChangeMediaName: (media: MediaGalleryItem) => void
  @Input() allowedMimeTypes: string[] = [...mimeTypes.IMAGES, ...mimeTypes.DOCUMENTS]

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('options') set changeOptions(newOptions: MediaListOptions) {
    this.options = { ...newOptions, ...containerOptions }
  }

  @Output() dirtyChange = new EventEmitter<void>()
  @Output() mediaClick = new EventEmitter<MediaField>()
  @Output() mediasChange = new EventEmitter<MediaField[]>()

  public options: MediaListOptions = containerOptions

  constructor(private readonly dialog: MatDialog) {}

  public add(mediaList: MediaListComponent) {
    if (!this.signaturePicker) {
      mediaList.onAddMediaClick()
    } else {
      this.openSignatureDialog(mediaList)
    }
  }

  private openSignatureDialog(mediaList: MediaListComponent) {
    const signatureHeight = 450
    const signatureWidth = 450

    this.dialog
      .open<SignatureDialogComponent, SignatureDialogOptions, SignatureDialogResult>(SignatureDialogComponent, {
        data: {
          height: signatureHeight,
          width: signatureWidth,
          title: this.title,
          disabled: this.disabled,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return
        }

        mediaList.addMedia(this.mapSignatureDialogResultToMediaField(res))
      })
  }

  private mapSignatureDialogResultToMediaField(result: SignatureDialogResult): MediaField {
    const dto = new MediaField()

    dto.id = `${+new Date()}`
    dto.mimeType = 'image/png'
    dto.name = result.name
    dto.organization = result.organization
    dto.isNewImage = true

    // decode from base 64
    const blobBin = atob(result.image.src.split(',')[1])
    const byteArray: number[] = []
    for (let i = 0; i < blobBin.length; i++) {
      // write the bytes to an array
      byteArray.push(blobBin.charCodeAt(i))
    }

    // create the File object
    dto.file = new File([new Uint8Array(byteArray)], result.name, { type: 'image/png' })
    dto.fileName = result.name
    dto.url = result.image.src
    dto.downloadUrl = dto.url

    return dto
  }
}
