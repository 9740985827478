import { Type } from 'class-transformer'
import {
  ArrayMinSize,
  IsArray,
  IsBoolean,
  IsDateString,
  IsHexColor,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Length,
  ValidateIf,
  ValidateNested,
} from 'class-validator'
import { ArrayParse, BooleanParse, StringCleanup, StringTrim } from '../decorators'
import { LabelValueDTO } from '../shared/label-value.dto'
import { VistaDTO, VistaListDTO } from '../shared/vista.dto'
import { SiteDTO, SiteLevelDTO } from '../site/site.dto'
import { VenueDTO } from '../venue/venue.dto'
import { EventPrintTemplateListDTO, EventPrintTemplateManageDTO } from './event-print-template.dto'

export class EventDTO extends VistaDTO {
  shortName?: string
  imageUrl?: string
  /* eslint-disable @typescript-eslint/camelcase */
  imageUrl_200x200?: string
  startDate?: Date
  endDate?: Date
  venues?: VenueDTO[]
  sites?: SiteDTO[]
  competitionId?: string
  keys?: EventKeyDTO[]
  projects?: LabelValueDTO[]
  templates?: EventPrintTemplateListDTO[]
  canBeDeleted?: CanBeDeletedDTO
  hasLayersToCopy?: boolean
  primaryColor?: string
  accentColor?: string
  seasonId?: number
  availableApplications?: string[]
  numberOfSites?: number
  numberOfVenues?: number
  layerCreationProcessRunning?: boolean

  constructor() {
    super()
    this.imageUrl = null
    this.imageUrl_200x200 = null
    this.shortName = null
  }
}

export class CanBeDeletedDTO {
  value: boolean
  reason: CannotBeDeletedReason
}

export enum CannotBeDeletedReason {
  INTEGRATED_WITH_FAME = 'INTEGRATED_WITH_FAME',
  HAVE_LAYERS = 'HAVE_LAYERS',
}

export class EventListDTO extends VistaListDTO {
  shortName?: string
  imageUrl?: string
  // eslint-disable @typescript-eslint/camelcase
  imageUrl_200x200?: string
  startDate?: Date
  endDate?: Date
  numberOfSites?: number
  numberOfVenues?: number
  competitionId?: string
  competitionIntegrationId?: string
  primaryColor?: string
  accentColor?: string
  isDomesticCompetitionTeamType?: boolean
  hasLayersToCopy?: boolean
  seasonId?: number

  constructor(event?: EventDTO) {
    super()
    this.id = event?.id ?? null
    this.name = event?.name ?? null
    this.shortName = event?.shortName ?? null
    this.imageUrl = event?.imageUrl ?? null
    this.imageUrl_200x200 = event?.imageUrl_200x200 ?? null
    this.numberOfSites = event?.sites?.length ?? 0
    this.numberOfVenues = event?.venues?.length ?? 0
    this.competitionId = event?.competitionId ?? null
    this.startDate = event?.startDate ?? null
    this.endDate = event?.endDate ?? null
    this.integrationId = event?.integrationId ?? null
    this.seasonId = event?.seasonId ?? null
  }
}

export class EventKeyDTO {
  @IsUUID()
  @IsNotEmpty()
  keyId: string

  @IsString()
  @IsOptional()
  alias?: string
}

export class EventVenueUpdateDTO {
  @IsNotEmpty()
  id: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  alias?: string

  @IsOptional()
  @IsUUID()
  defaultSiteId?: string

  @IsOptional()
  @IsUUID()
  blueprintLayerId?: string

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  sites?: EventSiteUpdateDTO[]

  // a fields used in EventManageComponent
  name?: string
  country?: string
  blueprintLayerDisplayName?: string
  blueprintLayerCreatedAt?: Date
}

export class EventSiteDTO {
  eventId: string
  siteId: string
  siteIntegrationId: string
  defaultLevelId: string
  siteName: string
  venueId: string
  venueIntegrationId: string
  venueName: string
}

export class EventSiteUpdateDTO {
  @IsNotEmpty()
  id: string

  @IsOptional()
  @StringTrim()
  @StringCleanup()
  alias?: string

  @IsOptional()
  siteTypeId?: string

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => SiteLevelDTO)
  levels?: SiteLevelDTO[]

  // fields used in EventManageComponent
  venueId?: string
  name?: string

  @IsOptional()
  @IsBoolean()
  createKeysLayers?: boolean

  @IsOptional()
  @IsBoolean()
  createZonesLayers?: boolean

  @IsOptional()
  @IsBoolean()
  createFlowsLayers?: boolean

  @IsOptional()
  @IsBoolean()
  createDotsLayers?: boolean

  @IsOptional()
  @IsBoolean()
  createLabelsLayers?: boolean
}

export class EventDataCopyVenueConfigurationDTO {
  @IsNotEmpty()
  @IsUUID('all')
  venueId: string

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  includeOverviewLevel?: boolean

  @IsOptional()
  @IsArray()
  @ArrayParse()
  sites?: EventDataCopySiteConfigurationDTO[]
}

export class EventDataCopySiteConfigurationDTO {
  @IsNotEmpty()
  @IsUUID('all')
  siteId: string

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsUUID('all', { each: true })
  levelIds?: string[]
}

export class EventDataCopyConfigurationDTO {
  @IsNotEmpty()
  @IsUUID('all')
  copyFromEventId: string

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ArrayMinSize(1)
  layerTypeIds: string[]

  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ArrayMinSize(1)
  @ValidateNested({ each: true })
  @Type(() => EventDataCopyVenueConfigurationDTO)
  venues: EventDataCopyVenueConfigurationDTO[]

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  mergeData?: boolean

  userId?: string
  copyDataStartDate?: Date
}

export class EventUpdateDTO {
  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  name: string

  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  shortName: string

  @StringTrim()
  @IsOptional()
  integrationId?: string

  @IsUUID()
  @IsNotEmpty()
  competitionId?: string

  @IsNumber()
  @IsNotEmpty()
  seasonId?: number

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => EventKeyDTO)
  keys: EventKeyDTO[]

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => EventSiteUpdateDTO)
  sites?: EventSiteUpdateDTO[]

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => EventVenueUpdateDTO)
  venues?: EventVenueUpdateDTO[]

  @IsOptional()
  projects?: string[]

  @IsDateString()
  @IsNotEmpty()
  startDate: Date

  @IsDateString()
  @IsNotEmpty()
  endDate: Date

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => EventPrintTemplateManageDTO)
  templates: EventPrintTemplateManageDTO[]

  @IsArray()
  @IsOptional()
  availableApplications: string[]

  @IsOptional()
  @IsString()
  @ValidateIf(e => !!e?.primaryColor)
  @IsHexColor()
  @Length(7)
  primaryColor?: string

  @IsOptional()
  @IsString()
  @ValidateIf(e => !!e?.accentColor)
  @IsHexColor()
  @Length(7)
  accentColor?: string

  @IsOptional()
  @ValidateIf(e => !!e?.dataCopyConfiguration)
  @Type(() => EventDataCopyConfigurationDTO)
  @ValidateNested()
  dataCopyConfiguration: EventDataCopyConfigurationDTO

  constructor(event?: EventDTO) {
    this.name = event?.name
    this.shortName = event?.shortName
    this.integrationId = event?.isFameIntegrated ? event.integrationId : ''
    this.competitionId = event?.competitionId
    this.seasonId = event?.seasonId
    this.keys = event?.keys ?? []
    this.projects = event?.projects.map(p => p.value) ?? []
    this.availableApplications = event?.availableApplications
    this.sites = []
    this.venues = []

    this.startDate = event?.startDate
    this.endDate = event?.endDate
    this.templates = event?.templates.map(t => new EventPrintTemplateManageDTO(t)) ?? []
    this.primaryColor = event?.primaryColor
    this.accentColor = event?.accentColor
  }

  public setVenues(venues: VenueDTO[]) {
    this.venues = venues.map(v => EventUpdateDTO.mapVenueDTOToEventVenueUpdateDTO(v))
  }

  public static mapVenueDTOToEventVenueUpdateDTO(s: VenueDTO): EventVenueUpdateDTO {
    return {
      id: s.id,
      name: s.name,
      country: s.country,
      defaultSiteId: s.defaultSiteId,
      blueprintLayerId: s.blueprintLayerId,
      blueprintLayerCreatedAt: s.blueprintLayerCreatedAt,
      blueprintLayerDisplayName: s.blueprintLayerDisplayName,
      alias: s.alias,
      sites: s.sites.map(e => this.mapSiteDtoToEventSiteUpdateDTO(e)),
    }
  }

  public static mapSiteDtoToEventSiteUpdateDTO(s: SiteDTO): EventSiteUpdateDTO {
    return {
      id: s.id,
      name: s.name,
      alias: s.alias,
      siteTypeId: s.eventSiteType?.[0]?.siteTypeId ?? null,
      venueId: s.venueId,
      levels: s.configuration?.levels || [],
      createDotsLayers: s.allLevelsHaveDotsLayer,
      createFlowsLayers: s.allLevelsHaveFlowsLayer,
      createKeysLayers: s.allLevelsHaveKeysLayer,
      createLabelsLayers: s.allLevelsHaveLabelsLayer,
      createZonesLayers: s.allLevelsHaveZonesLayer,
    }
  }
}

export class EventCreateDTO extends EventUpdateDTO {}
