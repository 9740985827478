import { IsArray, IsBoolean, IsNotEmpty, IsOptional } from 'class-validator'
import { BooleanParse, StringCleanup, StringTrim } from '../decorators'
import { EventDTO } from '../event/event.dto'
import { VistaDTO, VistaListDTO } from '../shared/vista.dto'

export class EventCycleDTO extends VistaDTO {
  name: string
  shortName: string
  manageLayers: boolean
  imageUrl: string
  events?: Array<EventDTO>
  constructor() {
    super()
    this.shortName = null
    this.events = null
    this.imageUrl = null
  }
}

export class EventCycleListDTO extends VistaListDTO {
  name: string
  shortName: string
  imageUrl: string
  numberOfEvents: number

  constructor() {
    super()
    this.shortName = null
    this.imageUrl = null
    this.numberOfEvents = 0
  }
}

export class EventCycleUpdateDTO {
  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  name: string

  @IsBoolean()
  @BooleanParse()
  @IsOptional()
  manageLayers: boolean

  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  shortName: string

  @IsArray()
  @IsNotEmpty()
  events: Array<EventDTO>

  constructor(model?: EventCycleDTO) {
    this.name = model?.name
    this.manageLayers = model?.manageLayers ?? true
    this.shortName = model?.shortName
    this.events = model?.events ?? []
  }
}

export class EventCycleCreateDTO extends EventCycleUpdateDTO {}
